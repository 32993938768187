import { Component, Inject, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { TranslationService } from '../../shared/services/translations.service'
import { LanguageChangeService } from '../../shared/services/language-change.service'
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-layout',
  template: `
    <app-navigation *ngIf="showNavFooter" [lang]="lang"></app-navigation>
    <router-outlet></router-outlet>
    <app-footer *ngIf="showNavFooter" [lang]="lang"></app-footer>
    <ngx-ez-modal-outlet *ngIf="showNavFooter"></ngx-ez-modal-outlet>
  `,
})
export class LayoutComponent implements OnDestroy {
  showNavFooter: boolean = true;
  lang: string = '';

  private subscription: Subscription;
  private isBrowser: boolean = false;

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private cookie: CookieService,
    private translationService: TranslationService,
    private languageChangeService: LanguageChangeService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // Split the URL by '/' and get the first path segment
      const pathRoot = event.urlAfterRedirects.split('?')[0].split('/');
      // Check whether the base path segment is in the array and set showNavFooter
      if (this.translationService.validLangs.includes(pathRoot[1])) {
        this.showNavFooter = !['buy-authenticate', 'authenticate'].includes(pathRoot[2]);
        this.lang = pathRoot[1];
       } else {
        this.showNavFooter = !['buy-authenticate', 'authenticate'].includes(pathRoot[1]);
        this.lang = 'en';
      }

      this.languageChangeService.setLanguage(this.lang);
      this.document.documentElement.lang = this.lang;
    });
  }

  ngOnInit() {
    this.languageChangeService.languageChanged.subscribe((newLang: string) => {
      this.lang = newLang;
      this.document.documentElement.lang = this.lang;
    });

    this.route.params.subscribe(params => {
      if (Object.keys(params).length === 0) {
        const url = this.router.url.split('/');
        if (this.translationService.validLangs.includes(url[1])) {
          this.lang = url[1];
        } else {
          this.lang = this.cookie.get('gg-lang') || 'en';
        }
      } else {
        this.lang = params['lang'] || 'en';
      }
      this.document.documentElement.lang = this.lang;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}