import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {of} from 'rxjs';
import {catchError, take, tap} from 'rxjs/operators';

@Injectable()
export class PriceService {
  private readonly currencyRates = {eur: 0.70};
  private prices = {};

  constructor(private http: HttpClient) {
  }

  public fetchPrices(version?: number) {
    const requiredVersion = version ? version : 'latest';
    if (this.prices[requiredVersion]) {
      return of(this.prices[requiredVersion]);
    } else {
      return this.http.get('/prices/' + requiredVersion).pipe(tap(prices => {
        this.prices[requiredVersion] = prices;
      }));
    }
  }

  async getServicePrice(service, skipServerCheck = false): Promise<{ price: number, discountedPrice: number, oldPrice: number, cashback: number, sd: string }> {
    const resp = await this.http.post<any>('/prices/calculate', service)
      .pipe(take(1), catchError(e => of({error: 'Failed to calculate price!'})))
      .toPromise();
    if (resp.error) {
      throw new Error(resp.error);
    }

    return resp;
  }

  calculateRemainingServicePrice(order) {
    const newService = {
      type: order.type,
      service: order.service,
      currency: order.currency,
      isExpress: order.isExpress,
      isSoloOnly: order.isSoloOnly,
      isWithStreaming: order.isWithStreaming,
      isWithCharacters: order.isWithCharacters,
      isWithPaidCharacters: order.isWithPaidCharacters,
      game: order.game,
      server: order.server,
      queue: order.queue,
      current: null,
      desired: null,
      lp: null,
      wins: null,
      losses: null,
      promos: null
    };

    if (newService.service === 'placements') {
      newService.current = order.startingRank;
      newService.desired = order.desiredGames - order.currentGames;
    }

    if (newService.service === 'normalgames') {
      newService.desired = order.desiredWins - order.currentWins;
    }

    if (newService.service === 'netwins') {
      newService.current = order.startingRank;
      newService.desired = order.desiredWins - order.currentWins;
    }

    if (newService.service === 'games') {
      newService.current = order.startingRank;
      newService.desired = order.desiredGames - order.currentGames;
    }

    if (newService.service === 'masteries') {
      newService.current = order.currentRank;
      newService.desired = order.desiredRank;
    }

    if (newService.service === 'leveling') {
      newService.current = order.currentRank;
      newService.desired = order.desiredRank;
    }

    if (newService.service === 'divisions') {
      newService.current = order.currentRank;
      newService.desired = order.desiredRank;
      newService.lp = order.currentLp;
      newService.promos = order.currentPromos;
      newService.wins = (order.currentPromos ? (order.currentPromos.match(/w/g) || []).length : 0);
      newService.losses = (order.currentPromos ? (order.currentPromos.match(/l/g) || []).length : 0);
    }

    if (newService.service === 'badges') {
      newService.current = order.currentRank;
      newService.desired = order.desiredRank;
    }

    return this.getServicePrice(newService, true);
  }
}
